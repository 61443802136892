<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tên sách </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="name" rules="required" v-slot="{ errors }">
          <hnr-input :text="detailBook.name" v-model="detailBook.name" @input="onKeyUp"></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="slug" rules="required" v-slot="{ errors }">
          <hnr-input :text="slug" v-model="detailBook.slug"></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center mt-2">
      <v-col cols="2" class="pa-1">
        <span> Lớp học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-select
            v-model="detailBook.grade_id"
            :items="grade"
            item-text="name"
            item-value="id"
            label="Nhập lớp học"
            multiple
            chips
            return-object
            dense
            outlined
        >
        </v-select>
      </v-col>
    </v-row>
    <!--    <v-row class="align-center justify-center">-->
    <!--      <v-col cols="2" class="pa-1">-->
    <!--        <span> Nội dung </span>-->
    <!--      </v-col>-->
    <!--      <v-col cols="12" class="pa-1">-->
    <!--          <ck-content-->
    <!--              id="lesson_content"-->
    <!--              :content="detailBook.content"-->
    <!--              :slug="slug"-->
    <!--              @getData="updateContent"-->
    <!--              v-model="detailBook.content"-->
    <!--          ></ck-content>-->
    <!--      </v-col>-->
    <!--    </v-row>-->

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Kích hoạt </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="status" rules="required" v-slot="{ errors }">
          <v-switch v-model="detailBook.status"></v-switch>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Sắp xếp </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="convertNumberToString(detailBook.order)"
            v-model="detailBook.order"
        ></hnr-input>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import mixin from "@/store/mixin";
import CkContent from "@/components/ck-content.vue";

export default {
  mixins: [mixin],
  props: {
    book: Object,
    grade: Array,
    isNew: {type: Boolean, default: false},
  },
  components: {
    CkContent,
    HnrInput,
  },
  data() {
    return {
      image: {},
      show: true,
      detailBook: {},
      name: "",
      slug: ""
    };
  },
  watch: {
    book: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailBook = newVal;
      },
    },
    detailBook: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.slug = newVal.slug
        if (!this.isNew) {
          this.detailBook.grade_id = newVal.grade_id
        }
      },
    },
  },
  methods: {
    onKeyUp(value) {
      this.detailBook.slug = this.sanitizeTitle(value);
    },
    updateContent(val) {
      this.detailBook.content = val;
    },
    convertNumberToString(number) {
      if (number !== undefined) {
        return number.toString();
      }
      return "0";
    },
  },
};
</script>