<template>
  <v-container>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-tabs v-model="tabIndex">
        <v-tab v-for="item in items" :key="item.id">{{ item.tab }}</v-tab>
      </v-tabs>
      <v-tabs-items class="tab-content" v-model="tabIndex">
        <v-tab-item active>
          <book-content
              class="tab-detail"
              :book="detailBook"
              :grade="listGrade"
              :isNew="isCreate"
          ></book-content>
        </v-tab-item>
      </v-tabs-items>
      <v-row v-if="isCreate" class="align-center justify-center ma-0 pt-5">
        <v-btn color="primary" @click="handleSubmit(saveSubject)">Tạo sách</v-btn>
      </v-row>
      <v-row v-else class="align-center justify-center ma-0 pt-5">
        <v-btn color="primary" @click="handleSubmit(updateSubject)">Cập nhật sách</v-btn>
      </v-row>
    </ValidationObserver>
  </v-container>
</template>

<script>
import SubjectContent from "@/components/subject/SubjectContent.vue";
import BookContent from "@/components/book/BookContent.vue";

import {mapGetters, mapState} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {SET_LOADING} from "@/store/loading.module";

import {SAVE_BOOK, GET_DETAIL_BOOK, UPDATE_BOOK} from "@/store/book.module";
import {GET_LIST_GRADE} from "@/store/grade.module";
import {SUCCESS, ERROR} from "@/store/alert.module";

export default {
  data() {
    return {
      id: "",
      isCreate: true,
      tabIndex: 0,
      items: [{id: 0, tab: "Thông tin chủ đề"}],
      headers: [
        {
          text: "id",
          value: "id",
        },
        {text: "Tên Chuyên mục", value: "name"},
      ],
      grade_id: [],
      detailBook: {
        id: '',
        name: "",
        slug: "",
        content: "",
        status: 0,
        order: "",
        grade_id: []
      },
    };
  },
  components: {
    BookContent,
    SubjectContent,
  },
  computed: {
    ...mapGetters({
      book: "detailBook",
      listGrade: "listGrade",
    }),
  },
  watch: {
    book: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailBook = newVal;
        if (newVal.grade_id) {
          let idArray = newVal.grade_id.map(item => item.id);
          this.grade_id = idArray;
        }
      },
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.$store.dispatch(GET_LIST_GRADE)
    if (this.id) {
      this.isCreate = false;
      this.$store.dispatch(SET_BREADCRUMB, [{title: "Sửa sách"}]);

      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(GET_DETAIL_BOOK, this.id)
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    } else {
      this.isCreate = true;
      this.$store.dispatch(SET_BREADCRUMB, [{title: "Thêm sách"}]);
    }
  },
  methods: {
    updateSubject() {
      let payload = {
        id: this.id,
        name: this.detailBook.name,
        status: this.detailBook.status ? 1 : 0,
        order: this.detailBook.order,
        content: this.detailBook.content,
        slug: this.detailBook.slug,
        grade_id: JSON.stringify(this.grade_id),
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(UPDATE_BOOK, payload)
          .then((data) => {
            if (data.status) {
              this.$store.dispatch(SUCCESS, "Cập nhật sách thành công!", {
                root: true,
              });
              this.$router.push("/list-book");
              this.detailBook = {}
            } else {
              if (data.errorCode == 424) {
                this.$store.dispatch(ERROR, data.message, {
                  root: true,
                });
              } else {
                this.$store.dispatch(ERROR, data.message, {
                  root: true,
                });
              }
            }
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(ERROR, "Cập nhật sách thất bại!", {
              root: true,
            });
            this.$store.dispatch(SET_LOADING, false);
          });
    },
    saveSubject() {
      let payload = {
        name: this.detailBook.name,
        status: this.detailBook.status ? 1 : 0,
        order: this.detailBook.order,
        content: this.detailBook.content ?? '',
        slug: this.detailBook.slug,
        grade_id: JSON.stringify(this.grade_id),
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(SAVE_BOOK, payload)
          .then((data) => {
            if (data.status) {
              this.$store.dispatch(SUCCESS, "Thêm sách thành công!", {
                root: true,
              });
              this.$router.push("/list-book");
            } else {
              this.$store.dispatch(ERROR, data.message, {
                root: true,
              });
            }
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(ERROR, "Thêm sách thất bại!", {root: true});
            this.$store.dispatch(SET_LOADING, false);
          });
    },
  },
};
</script>

<style lang="scss">
.tab-detail {
  margin-top: 15px;
}
</style>
